import { Component } from '@angular/core';
import { SavedCartsService } from '../../services/saved-carts.service';

@Component({
  selector: 'app-new-cart',
  templateUrl: './new-cart.component.html',
  styleUrl: './new-cart.component.scss'
})
export class NewCartComponent {

  constructor(private savedCartService: SavedCartsService) {}

  public handleClick() {
    this.savedCartService.callcomponentNewCartFunction();
  }
}
